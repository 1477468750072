import React from "react";
import BG from "../../assets/imgs/banner-uk.png";

function UK() {
  const bg = BG;

  return (
    <div>
      <div id="page" className="site site_wrapper">
        <div
          className="breadcrumb-area bg-default  pt-130 pb-130"
          style={{ marginTop: 166 }}
          data-background={bg}
        >
          <div className="container kd-container-1">
            <div className="row">
              <div className="col-xxl-12">
                <div className="breadcrumb-wrap"></div>
              </div>
            </div>
          </div>
        </div>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-e59fe91 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="e59fe91"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-106a241"
              data-id="106a241"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-06bbe7e elementor-widget elementor-widget-go-s-heading"
                  data-id="06bbe7e"
                  data-element_type="widget"
                  data-widget_type="go-s-heading.default"
                >
                  <div className="elementor-widget-container">
                    <div className="prthalign" style={{ margin: "2rem 0" }}>
                      <h1 className="elementor-gt-heading text-center kd-section-title-1 kd-title-ani kd-split-text">
                        Study In United Kingdom
                      </h1>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-495e7eb elementor-widget elementor-widget-go-pricing-paln"
                  data-id="495e7eb"
                  data-element_type="widget"
                  data-widget_type="go-pricing-paln.default"
                >
                  <div className="elementor-widget-container">
                    <div
                      className="kd-price-4-content-wrap d-block"
                      style={{ paddingBottom: 70 }}
                    >
                      <div className="kd-price-4-content-bg img-cover fix txaa-scalex-down">
                        <img
                          decoding="async"
                          src="https://universitybureau.com/content/uploads/2024/06/p3-item-bg-1.webp"
                          alt=""
                        />
                      </div>
                      <div className="kd-price-4-content">
                        <h5
                          className="title kd-heading-1 kd-font-900 has-clr-white wow"
                          data-splitting=""
                        >
                          Overview
                        </h5>
                        <p
                          className="disc kd-para-1 has-clr-white wow "
                          data-splitting=""
                        >
                          The United Kingdom (UK) has long been a global center for education. Studying in the UK is a singular and interesting experience, renowned for its top universities, diversified culture, and long history. With origins dating back to the 12th and 13th centuries, the United Kingdom (UK) is home to some of the oldest universities and colleges in the entire globe. Study in the UK offers an unparalleled academic experience combined with cultural diversity and historical richness.
                        </p>
                      </div>
                      <div className="kd-price-4-content-list">
                        <h4
                          className="title kd-heading-1 kd-font-900 has-clr-white wow"
                          data-splitting=""
                        >
                          benefits of United Kingdom
                        </h4>
                        <ul className="kd-list-1 kd-heading-1 kd-font-800" style={{ maxWidth: '1000px' }}>
                          <li>
                            <i className="fa-solid fa-circle-check" />
                            <span className="wow" data-splitting="">
                            UK education is recognised all over the World.
                            </span>
                          </li>
                          <li>
                            <i className="fa-solid fa-circle-check" />
                            <span className="wow" data-splitting="">
                            VISA Success Rate: 98%
                            </span>
                          </li>
                          <li>
                            <i className="fa-solid fa-circle-check" />
                            <span className="wow" data-splitting="">
                            Chances of Scholarship.
                            </span>
                          </li>
                          <li>
                            <i className="fa-solid fa-circle-check" />
                            <span className="wow" data-splitting="">
                            The UK offers a post-study work visa and courses with internships.
                            </span>
                          </li>
                          <li>
                            <i className="fa-solid fa-circle-check" />
                            <span className="wow" data-splitting="">
                            UK courses are shorter and more intensive, leading to faster graduation.
                            </span>
                          </li>
                          <li>
                            <i className="fa-solid fa-circle-check" />
                            <span className="wow" data-splitting="">
                            Average costs in the UK range from £11,400 to £38,000, estimated average fees £22,200.
                            </span>
                          </li>
                          <li>
                            <i className="fa-solid fa-circle-check" />
                            <span className="wow" data-splitting="">
                            International students can work part-time for 20 hours/week and full-time during holidays.
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-e59fe91 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="e59fe91"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-106a241"
              data-id="106a241"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-06bbe7e elementor-widget elementor-widget-go-s-heading"
                  data-id="06bbe7e"
                  data-element_type="widget"
                  data-widget_type="go-s-heading.default"
                >
                  <div className="elementor-widget-container">
                    <div className="prthalign" style={{ margin: "2rem 0" }}>
                      <h1 className="elementor-gt-heading text-center kd-section-title-1 kd-title-ani kd-split-text">
                        Why Choose United Kingdom for Your <br />
                        Academic Pursuits
                      </h1>
                      <p>
                        Choosing the United Kingdom for your academic pursuits can be a rewarding decision, and there are several compelling reasons why students opt to study in the UK:
                      </p>
                    </div>
                  </div>
                  <div className="elementor-widget-container">
                    <div
                      id="categories-2"
                      className="widget_categories sidebar-widget"
                    >
                      <ul>
                        <li className="cat-item cat-item-25">
                          <a href="/#">
                            <div style={{ padding: 10 }}>
                              <b>High-Quality Education:</b>
                              <br />
                              The UK is renowned for its world-class education system, with a long history of academic excellence. Many universities consistently rank among the top globally, offering rigorous and well-recognized programs.
                            </div>
                          </a>
                        </li>
                        <li className="cat-item cat-item-25">
                          <a href="/#">
                            <div style={{ padding: 10 }}>
                              <b>Diverse Range of Courses:</b>
                              <br />
                              UK universities provide a wide array of courses and degrees, catering to various academic interests and career aspirations. The flexibility of the education system allows students to choose a program that aligns with their goals.
                            </div>
                          </a>
                        </li>
                        <li className="cat-item cat-item-25">
                          <a href="/#">
                            <div style={{ padding: 10 }}>
                              <b>Shorter Duration of Programs:</b>
                              <br />
                              Undergraduate programs in the UK typically last three years, and master's programs can be completed in one year. This allows students to enter the workforce sooner than in many other countries, saving both time and money.
                            </div>
                          </a>
                        </li>
                        <li className="cat-item cat-item-25">
                          <a href="/#">
                            <div style={{ padding: 10 }}>
                              <b>Research Opportunities:</b>
                              <br />
                              The UK is a hub for research and innovation, with many universities actively involved in groundbreaking research. Students often have access to state-of-the-art facilities and can engage in research projects during their studies.
                            </div>
                          </a>
                        </li>
                        <li className="cat-item cat-item-25">
                          <a href="/#">
                            <div style={{ padding: 10 }}>
                              <b>Cultural Diversity:</b>
                              <br />
                              The UK is a melting pot of cultures, providing an enriching and diverse environment for international students. Exposure to different cultures fosters a global perspective and enhances the overall educational experience.
                            </div>
                          </a>
                        </li>
                        <li className="cat-item cat-item-25">
                          <a href="/#">
                            <div style={{ padding: 10 }}>
                              <b>Language Advantage:</b>
                              <br />
                              Studying in an English-speaking country provides students with the opportunity to improve their English language skills. This is beneficial not only academically but also in the global job market.
                            </div>
                          </a>
                        </li>
                        <li className="cat-item cat-item-25">
                          <a href="/#">
                            <div style={{ padding: 10 }}>
                              <b>Historical and Cultural Significance:</b>
                              <br />
                              The UK boasts a rich history and cultural heritage, with landmarks, museums, and historical sites that offer a unique backdrop for students. Living in the UK provides a chance to explore this rich tapestry.
                            </div>
                          </a>
                        </li>
                        <li className="cat-item cat-item-25">
                          <a href="/#">
                            <div style={{ padding: 10 }}>
                              <b>Global Networking Opportunities:</b>
                              <br />
                              The UK attracts students from around the world, offering a diverse and extensive network. Building connections with classmates, professors, and professionals during your studies can open doors to global opportunities.
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-3163e91 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
          data-id="3163e91"
          data-element_type="section"
          style={{
            backgroundImage:
              "url(https://universitybureau.com/content/uploads/2024/06/p1-bg-1.webp)",
            backgroundSize: "cover",
            marginTop: "2rem",
          }}
        >
          <div className="elementor-container elementor-column-gap-no">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-888d83f"
              data-id="888d83f"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-789f478 elementor-widget elementor-widget-go-pricing-tab"
                  data-id="789f478"
                  data-element_type="widget"
                  data-widget_type="go-pricing-tab.default"
                >
                  <div className="elementor-widget-container">
                    <div className="kd-price-1-area">
                      <div className="container kd-conainer-1">
                        <div className="kd-price-1-row d-flex">
                          {/* left-side */}
                          <div
                            className="kd-price-1-left w-100"
                            style={{
                              padding: 50,
                              justifyContent: "space-between",
                              gap: 200,
                            }}
                          >
                            {/* tabs-btn */}
                            <ul
                              className="kd-price-1-tabs-btn"
                              id="myTab"
                              role="tablist"
                            >
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link active kd-heading-1 has-clr-white kd-font-800 font-c-40"
                                  id="home-tab0"
                                  data-bs-toggle="tab"
                                  data-bs-target="#home0"
                                  type="button"
                                  role="tab"
                                  aria-controls="home0"
                                  aria-selected="true"
                                >
                                  <img
                                    decoding="async"
                                    src="https://universitybureau.com/content/uploads/2024/06/p1-il-2.webp"
                                    alt=""
                                  />
                                  Foundations{" "}
                                </button>
                              </li>
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link  kd-heading-1 has-clr-white kd-font-800 font-c-40"
                                  id="home-tab1"
                                  data-bs-toggle="tab"
                                  data-bs-target="#home1"
                                  type="button"
                                  role="tab"
                                  aria-controls="home1"
                                  aria-selected="true"
                                >
                                  <img
                                    decoding="async"
                                    src="https://universitybureau.com/content/uploads/2024/06/p1-il-2.webp"
                                    alt=""
                                  />
                                  Undergraduate
                                </button>
                              </li>
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link  kd-heading-1 has-clr-white kd-font-800 font-c-40"
                                  id="home-tab2"
                                  data-bs-toggle="tab"
                                  data-bs-target="#home2"
                                  type="button"
                                  role="tab"
                                  aria-controls="home2"
                                  aria-selected="true"
                                >
                                  <img
                                    decoding="async"
                                    src="https://universitybureau.com/content/uploads/2024/06/p1-il-2.webp"
                                    alt=""
                                  />
                                  Post Graduate
                                </button>
                              </li>
                            </ul>
                            {/* tabs-content */}
                            <div
                              className="tab-content kd-price-1-tabs-content"
                              id="myTabContent"
                            >
                              <div
                                className="tab-pane fade show active"
                                id="home0"
                                role="tabpanel"
                                aria-labelledby="home-tab0"
                              >
                                <div className="kd-price-1-tabs-content-wrap">
                                  <div className="">
                                    <h4 className="title kd-heading-1 kd-font-900 has-clr-white">
                                      Required to hold a bachelor's degree or equivalent qualification from a recognized institution.
                                      <br/>
                                      <br/>
                                      IELTS score of 5.5 overall. 
                                      <br />
                                      <br />
                                      Foundations/pre-masters Streams
                                      <br />
                                      <br />
                                    </h4>
                                    <ul className="kd-list-1 kd-heading-1 kd-font-800 d-block">
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Humanities and Social Sciences
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Finance and Economics
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Integrated Pre-Masters Programmes
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="tab-pane fade "
                                id="home1"
                                role="tabpanel"
                                aria-labelledby="home-tab1"
                              >
                                <div className="kd-price-1-tabs-content-wrap">
                                  <div className="">
                                    <h4 className="title kd-heading-1 kd-font-900 has-clr-white">
                                      Generally, a minimum of 55% in HSC (Higher Secondary Certificate) is needed.
                                      <br/>
                                      <br/>
                                      IELTS score of 6.0 overall, with no individual band below 5.5. 
                                      <br />
                                      <br />
                                      Undergraduate Streams
                                      <br />
                                      <br />
                                    </h4>
                                    <ul className="kd-list-1 kd-heading-1 kd-font-800 d-block">
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Art And Design
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Biology/Medicine
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Business Administration/Management
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Engineering
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="tab-pane fade "
                                id="home2"
                                role="tabpanel"
                                aria-labelledby="home-tab2"
                              >
                                <div className="kd-price-1-tabs-content-wrap">
                                  <div className="">
                                    <h4 className="title kd-heading-1 kd-font-900 has-clr-white">
                                      A minimum of 60-65% of a bachelor's degree is typically required.
                                      <br/>
                                      <br/>
                                      IELTS score of 6.5 overall, with no individual band below 6.0. 
                                      <br />
                                      <br />
                                      Postgraduate Streams
                                      <br />
                                      <br />
                                    </h4>
                                    <ul className="kd-list-1 kd-heading-1 kd-font-800 d-block">
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Arts
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Computer Science & Data Sciences
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Engineering & Technology
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        MBA in Marketing/ International Business
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Law/Philosophy
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-88d3249 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="88d3249"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-a06ae77"
              data-id="a06ae77"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-aeadd90 elementor-widget elementor-widget-go-img--bx"
                  data-id="aeadd90"
                  data-element_type="widget"
                  data-widget_type="go-img--bx.default"
                >
                  <div className="elementor-widget-container">
                    <div className="kd-about-3-img-wrap txaa-slide-down-1">
                      <div className="kd-about-3-img img-cover fix kd-img-ani-1">
                        <img
                          decoding="async"
                          src="https://universitybureau.com/content/uploads/2024/06/a3-img-1.webp"
                          alt=""
                        />
                      </div>
                      <div className="kd-about-3-img-2 img-cover fix txaa-slide-down-1-item">
                        <img
                          decoding="async"
                          src="https://universitybureau.com/content/uploads/2024/06/a3-img-2.webp"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4203749"
              data-id={4203749}
              data-element_type="column"
              style={{
                flexDirection: "column",
                padding: "20px 70px",
                paddingRight: 0,
              }}
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-9ceb82e elementor-widget elementor-widget-go-s-heading"
                  data-id="9ceb82e"
                  data-element_type="widget"
                  data-widget_type="go-s-heading.default"
                >
                  <div className="elementor-widget-container">
                    <div className="prthalign">
                      <h1 className="elementor-gt-heading kd-section-title-1 kd-title-ani kd-split-text">
                        Application Procedure in United Kingdom
                      </h1>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-66aff77 elementor-widget elementor-widget-go-list-item"
                  data-id="66aff77"
                  data-element_type="widget"
                  data-widget_type="go-list-item.default"
                >
                  <div className="elementor-widget-container">
                    <ul
                      className="kd-list-1 kd-heading-1 kd-font-800 "
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <li className="font-c-24">
                        <i className="fa-solid fa-circle-check" />
                        <span className="wow" data-splitting="">
                          Decide universities and courses 
                        </span>
                      </li>
                      <li className="font-c-24">
                        <i className="fa-solid fa-circle-check" />
                        <span className="wow" data-splitting="">
                          Submit Application Through UCAS
                        </span>
                      </li>
                      <li className="font-c-24">
                        <i className="fa-solid fa-circle-check" />
                        <span className="wow" data-splitting="">
                          Prepare Supporting Documents
                        </span>
                      </li>
                      <li className="font-c-24">
                        <i className="fa-solid fa-circle-check" />
                        <span className="wow" data-splitting="">
                          Write a Personal Statement
                        </span>
                      </li>
                      <li className="font-c-24">
                        <i className="fa-solid fa-circle-check" />
                        <span className="wow" data-splitting="">
                          Submit the Application and Pay the Fees
                        </span>
                      </li>
                      <li className="font-c-24">
                        <i className="fa-solid fa-circle-check" />
                        <span className="wow" data-splitting="">
                          Receive Offers
                        </span>
                      </li>
                      <li className="font-c-24">
                        <i className="fa-solid fa-circle-check" />
                        <span className="wow" data-splitting="">
                          Accept Offer and Apply for Visa
                        </span>
                      </li>
                      <li className="font-c-24">
                        <i className="fa-solid fa-circle-check" />
                        <span className="wow" data-splitting="">
                          Prepare for Arrival
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-19ff2bc elementor-widget elementor-widget-ft-btn"
                  data-id="19ff2bc"
                  data-element_type="widget"
                  data-widget_type="ft-btn.default"
                >
                  <div className="elementor-widget-container">
                    <div className="prthalign">
                      <a
                        href="/#"
                        target="_blank"
                        rel="nofollow"
                        aria-label="name"
                        className="kd-pr-btn-1 text-uppercase has-pr-clr"
                      >
                        <span
                          className="text"
                          data-back="find out more"
                          data-front="Apply Now"
                        />
                        <span className="icon">
                          <i className="fa-solid fa-arrow-right" />
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-e01eb3b elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="e01eb3b"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-fc8911c"
              data-id="fc8911c"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-59eb154 elementor-widget elementor-widget-go-s-heading"
                  data-id="59eb154"
                  data-element_type="widget"
                  data-widget_type="go-s-heading.default"
                >
                  <div className="elementor-widget-container">
                    <div className="prthalign">
                      <h6 className="kd-subtitle-1 kd-subtitle-ani-1 elementor-kadu-sub">
                        <img
                          decoding="async"
                          src="https://universitybureau.com/content/uploads/2024/05/star-1.webp"
                          alt=""
                        />
                        Required Documents
                      </h6>
                      <h1 className="elementor-gt-heading kd-section-title-1 kd-title-ani kd-split-text">
                      To apply for a student visa to study in the UK, it has certain requirements:
                      </h1>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-7ec771b elementor-widget elementor-widget-go-ct-info"
                  data-id="7ec771b"
                  data-element_type="widget"
                  data-widget_type="go-ct-info.default"
                >
                  <div className="elementor-widget-container">
                    <ul
                      className="kd-list-1 kd-heading-1 kd-font-500 "
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        maxWidth: "100%",
                      }}
                    >
                      <li className="font-c-20 custom-flex-box-1">
                        <i
                          className="fa-solid fa-circle-check font-c-40"
                          style={{ marginRight: 10 }}
                        />
                        <p className="m-0">
                          <b>Confirmation of Acceptance for Studies:</b>
                          <br />
                          The Confirmation of Acceptance for Studies (CAS) from a licensed UK university or college should be valid.
                        </p>
                      </li>
                      <li className="font-c-20 custom-flex-box-1">
                        <i
                          className="fa-solid fa-circle-check font-c-40"
                          style={{ marginRight: 10 }}
                        />
                        <p className="m-0">
                          <b>Financial Support:</b>
                          <br />
                          You must show that you have enough money to cover your course fees and living expenses which include bank statements or a sponsorship letter.
                        </p>
                      </li>
                      <li className="font-c-20 custom-flex-box-1">
                        <i
                          className="fa-solid fa-circle-check font-c-40"
                          style={{ marginRight: 10 }}
                        />
                        <p className="m-0">
                          <b>English Language Proficiency:</b>
                          <br />
                          Depending on your course, you may need to prove your English language proficiency through tests like IELTS or TOEFL.
                        </p>
                      </li>
                      <li className="font-c-20 custom-flex-box-1">
                        <i
                          className="fa-solid fa-circle-check font-c-40"
                          style={{ marginRight: 10 }}
                        />
                        <p className="m-0">
                          <b>Tuberculosis (TB) Test:</b>
                          <br />
                          Some students from certain countries may need to take a TB test as part of the visa application.
                        </p>
                      </li>
                      <li className="font-c-20 custom-flex-box-1">
                        <i
                          className="fa-solid fa-circle-check font-c-40"
                          style={{ marginRight: 10 }}
                        />
                        <p className="m-0">
                          <b>Healthcare Surcharge:</b>
                          <br />You'll need to pay the Immigration Health Surcharge as part of your application, which allows you to access the UK's National Health Service (NHS).
                        </p>
                      </li>
                      <li className="font-c-20 custom-flex-box-1">
                        <i
                          className="fa-solid fa-circle-check font-c-40"
                          style={{ marginRight: 10 }}
                        />
                        <p className="m-0">
                          <b>Biometric Information:</b>
                          <br />
                          It needs to provide your biometric information (fingerprints and a photo) as part of the application process.
                        </p>
                      </li>
                      <li className="font-c-20 custom-flex-box-1">
                        <i
                          className="fa-solid fa-circle-check font-c-40"
                          style={{ marginRight: 10 }}
                        />
                        <p className="m-0">
                          <b>Valid Passport:</b>
                          <br />
                          You'll need a valid passport or travel document to apply for the visa.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-d320ac4 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="d320ac4"
          data-element_type="section"
          style={{
            backgroundImage:
              "url(https://universitybureau.com/content/uploads/2024/06/p1-bg-1.webp)",
            backgroundSize: "cover",
            marginTop: "2rem",
            padding: "4rem 0",
          }}
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4af12a5"
              data-id="4af12a5"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-1649a90 elementor-widget elementor-widget-go-s-heading"
                  data-id="1649a90"
                  data-element_type="widget"
                  data-widget_type="go-s-heading.default"
                >
                  <div className="elementor-widget-container">
                    <div className="prthalign">
                      <h6
                        style={{ color: "#fff" }}
                        className="kd-subtitle-1 kd-subtitle-ani-1 elementor-kadu-sub"
                      >
                        Study fees
                      </h6>
                      <h1
                        style={{ color: "#fff" }}
                        className="elementor-gt-heading kd-section-title-1 kd-title-ani kd-split-text"
                      >
                        Cost to <br />
                        Study in United Kingdom
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-a3b221d"
              data-id="a3b221d"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <section
                  className="elementor-section elementor-inner-section elementor-element elementor-element-294c712 elementor-section-full_width txaa-slide-down-1 elementor-section-height-default elementor-section-height-default"
                  data-id="294c712"
                  data-element_type="section"
                >
                  <div className="elementor-container elementor-column-gap-no">
                    <div
                      className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-69d5dc8"
                      data-id="69d5dc8"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="elementor-element elementor-element-9bd8b2b elementor-widget elementor-widget-go-info-box"
                          data-id="9bd8b2b"
                          data-element_type="widget"
                          data-widget_type="go-info-box.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="kd-services-1-card  txaa-slide-down-1-item" style={{  marginRight: '10px' }}>
                              <div className="icon">
                                <img
                                  decoding="async"
                                  src="https://universitybureau.com/content/uploads/2024/05/s1-icon-1.webp"
                                  alt="List"
                                />
                              </div>
                              <h4 className="title kd-heading-1 kd-font-900">
                                <a href="/#" aria-label="name">
                                International Undergraduate
                                </a>
                              </h4>
                              <p className="disc kd-para-1">
                                £11,400 - £38,000
                              </p>
                              <p className="disc kd-para-1">
                              £22,200 <br/>(Estimated average cost)
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-69d5dc8"
                      data-id="69d5dc8"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="elementor-element elementor-element-9bd8b2b elementor-widget elementor-widget-go-info-box"
                          data-id="9bd8b2b"
                          data-element_type="widget"
                          data-widget_type="go-info-box.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="kd-services-1-card  txaa-slide-down-1-item">
                              <div className="icon">
                                <img
                                  decoding="async"
                                  src="https://universitybureau.com/content/uploads/2024/05/s1-icon-1.webp"
                                  alt="List"
                                />
                              </div>
                              <h4 className="title kd-heading-1 kd-font-900">
                                <a href="/#" aria-label="name">
                                International postgraduate
                                </a>
                              </h4>
                              <p className="disc kd-para-1">£9,000 - £30,000</p>
                              <p className="disc kd-para-1">£17,109<br/> (Estimated average cost)</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-d320ac4 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="d320ac4"
          data-element_type="section"
          style={{
            backgroundImage:
              "url(https://universitybureau.com/content/uploads/2024/06/p1-bg-1.webp)",
            backgroundSize: "cover",
            marginBottom: 20,
            padding: "4rem 0",
          }}
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-a3b221d"
              data-id="a3b221d"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <section
                  className="elementor-section elementor-inner-section elementor-element elementor-element-294c712 elementor-section-full_width txaa-slide-down-1 elementor-section-height-default elementor-section-height-default"
                  data-id="294c712"
                  data-element_type="section"
                >
                  <div className="elementor-container elementor-column-gap-no">
                    <div
                      className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-69d5dc8"
                      data-id="69d5dc8"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="elementor-element elementor-element-9bd8b2b elementor-widget elementor-widget-go-info-box"
                          data-id="9bd8b2b"
                          data-element_type="widget"
                          data-widget_type="go-info-box.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="kd-services-1-card  txaa-slide-down-1-item">
                              <table className="cost-table-design">
                                <tbody>
                                    <tr>
                                        <th><b>Expense Category</b></th>
                                        <th><b>Estimated Monthly Cost (£)</b></th>
                                    </tr>
                                    <tr>
                                        <td>Accommodation</td>
                                        <td>£554</td>
                                    </tr>
                                    <tr>
                                        <td>Food</td>
                                        <td>£45</td>
                                    </tr>
                                    <tr>
                                        <td>Groceries</td>
                                        <td>£155</td>
                                    </tr>
                                    <tr>
                                        <td>Public transport</td>
                                        <td>£54</td>
                                    </tr>
                                    <tr>
                                        <td>Mobile phone bill</td>
                                        <td>£18</td>
                                    </tr>
                                    <tr>
                                        <td>Household bill</td>
                                        <td>£80</td>
                                    </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4af12a5"
              data-id="4af12a5"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-1649a90 elementor-widget elementor-widget-go-s-heading"
                  data-id="1649a90"
                  data-element_type="widget"
                  data-widget_type="go-s-heading.default"
                  style={{ height: "100%" }}
                >
                  <div
                    className="elementor-widget-container"
                    style={{ height: "100%" }}
                  >
                    <div
                      className="prthalign custom-flex-box-2"
                      style={{ textAlign: "right", height: "100%" }}
                    >
                      <h6
                        style={{ color: "#fff" }}
                        className="kd-subtitle-1 kd-subtitle-ani-1 elementor-kadu-sub"
                      >
                        Study fees
                      </h6>
                      <h1
                        style={{ color: "#fff" }}
                        className="elementor-gt-heading kd-section-title-1 kd-title-ani kd-split-text"
                      >
                        Cost to <br />
                        Study in United Kingdom
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-e01eb3b elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="e01eb3b"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-fc8911c"
              data-id="fc8911c"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-59eb154 elementor-widget elementor-widget-go-s-heading"
                  data-id="59eb154"
                  data-element_type="widget"
                  data-widget_type="go-s-heading.default"
                >
                  <div className="elementor-widget-container">
                    <div className="prthalign">
                      <h6 className="kd-subtitle-1 kd-subtitle-ani-1 elementor-kadu-sub">
                        <img
                          decoding="async"
                          src="https://universitybureau.com/content/uploads/2024/05/star-1.webp"
                          alt=""
                        />
                        FAQs
                      </h6>
                      <h1 className="elementor-gt-heading kd-section-title-1 kd-title-ani kd-split-text">
                        Fequently Asked Questions
                      </h1>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-7ec771b elementor-widget elementor-widget-go-ct-info"
                  data-id="7ec771b"
                  data-element_type="widget"
                  data-widget_type="go-ct-info.default"
                >
                  <div className="elementor-widget-container">
                    <div className="tutor-mt-40 kd-course-details-content">
                      <div className="cd-video-accordion">
                        <div className="cd-video-accordion-item">
                          <h4 className="item-title tutor-accordion-item-header is-active">
                          1. What is the education system like in the UK?
                          </h4>
                          <div className="tutor-accordion-item-body" style={{}}>
                            <div className="item-body">
                              <p className="font-c-20">
                              The UK education system consists of primary, secondary, further education, and higher education. Higher education includes undergraduate (Bachelor's), postgraduate (Master's), and doctoral (Ph.D.) programs.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="cd-video-accordion-item">
                          <h4 className="item-title tutor-accordion-item-header">
                          2. How do I apply to universities in the UK?
                          </h4>
                          <div
                            className="tutor-accordion-item-body"
                          >
                            <div className="item-body">
                            Applications for undergraduate programs are typically made through the UCAS (Universities and Colleges Admissions Service) system, while postgraduate applications are made directly to the respective universities.
                            </div>
                          </div>
                        </div>
                        <div className="cd-video-accordion-item">
                          <h4 className="item-title tutor-accordion-item-header">
                          3. Are there scholarships available for international students?
                          </h4>
                          <div
                            className="tutor-accordion-item-body"
                          >
                            <div className="item-body">
                            Yes, there are various scholarships available for international students offered by universities, government bodies, and private organizations. Common ones include Chevening Scholarships, Commonwealth Scholarships, and university-specific scholarships.
                            </div>
                          </div>
                        </div>
                        <div className="cd-video-accordion-item">
                          <h4 className="item-title tutor-accordion-item-header">
                          4. What are the language requirements for international students? Master of Laws (LL.M.)
                          </h4>
                          <div
                            className="tutor-accordion-item-body"
                          >
                            <div className="item-body">
                            Most universities require proof of English proficiency, usually through tests such as IELTS or TOEFL.
                            </div>
                          </div>
                        </div>
                        <div className="cd-video-accordion-item">
                          <h4 className="item-title tutor-accordion-item-header">
                          5. How much does it cost to study in UK?
                          </h4>
                          <div
                            className="tutor-accordion-item-body"
                          >
                            <div className="item-body">
                            Tuition fees vary depending on the course and institution. On average, undergraduate international students might pay £10,000 to £30,000 per year, while postgraduate fees can range from £10,000 to £40,000 or more.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
export default UK;
