import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function SearchBar({ onSearch, initialValue = "" }) {
    const [searchTerm, setSearchTerm] = useState(initialValue);
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (window.location.pathname !== '/college-search') {
            // If we're not on the college search page, redirect with the search term
            navigate(`/college-search?name=${encodeURIComponent(searchTerm)}`);
        } else {
            // If we're already on the college search page, just trigger the search
            onSearch(searchTerm);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="college-search-bar">
            <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search colleges by name..."
                className="college-search-input"
            />
            <button type="submit" className="search-button">
                Search
            </button>
        </form>
    );
}

export default SearchBar;