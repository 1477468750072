import React from "react";

function SearchResult() {
  return (
    <div>
      <div id="page" className="site site_wrapper">
      </div>
    </div>
  );
}

export default SearchResult;
