import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/include/Navbar';
import Footer from './components/include/Footer';
import Students from './components/services/Students';
import Partners from './components/services/Partners';
import Canada from './components/studydestinations/Canada';
import Europe from './components/studydestinations/Europe';
import Australia from './components/studydestinations/Australia';
import UK from './components/studydestinations/Uk';
import USAPage from './components/studydestinations/Usa';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import News from './components/News';
import Logins from './components/Logins';
import SearchResult from './components/SearchResult';
import CollegeSearch from './components/CollegeSearch';
import Registerations from './components/Registerations';
import './App.css';
import { Helmet } from 'react-helmet';

function App() {

  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/News" element={<News />} />
          <Route path="/sign-up" element={<Registerations />} />
          <Route path="/sign-in" element={<Logins />} />
          <Route path="/search-result" element={<SearchResult />} />
          <Route path="/college-search" element={<CollegeSearch />} />
          <Route path="/services/students" element={<Students />} />
          <Route path="/services/partners" element={<Partners />} />
          <Route path="/studydestinations/canada" element={<Canada />} />
          <Route path="/studydestinations/europe" element={<Europe />} />
          <Route path="/studydestinations/australia" element={<Australia />} />
          <Route path="/studydestinations/uk" element={<UK />} />
          <Route path="/studydestinations/usa" element={<USAPage />} />
        </Routes>
        <Helmet>

          <script src="https://universitybureau.com/includes/js/jquery/jquery.min.js" id="jquery-core-js"></script>
          <script src="https://universitybureau.com/includes/js/jquery/jquery-migrate.min.js" id="jquery-migrate-js"></script>


        </Helmet>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
