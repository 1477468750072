import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";
import SearchBar from "./include/SearchBar";

function CollegeSearch() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [colleges, setColleges] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1); // Current page
    const [perPage, setPerPage] = useState(10); // Items per page
    const [totalColleges, setTotalColleges] = useState(0); // Total colleges

    const [filters, setFilters] = useState({
        name: searchParams.get('name') || "",
        country_id: "",
        campus: "",
        intake: "",
        application_fee: ""
    });

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSearch = (searchTerm) => {
        setFilters(prev => ({
            ...prev,
            name: searchTerm
        }));
        navigate(`/college-search?name=${encodeURIComponent(searchTerm)}`);
    };

    const fetchColleges = async () => {
        try {
            setLoading(true);
            setError(null);

            const queryString = Object.entries({ ...filters, per_page: perPage, page: currentPage })
                .filter(([_, value]) => value)
                .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
                .join('&');

            const response = await axios.get(`https://portal.universitybureau.com/api/colleges?${queryString}`);

            if (response.data && response.data.data) {
                setColleges(response.data.data);
                setTotalColleges(response.data.total); // Set total colleges
            } else {
                setColleges([]);
                setTotalColleges(0);
            }
        } catch (err) {
            setError('Failed to fetch colleges. Please try again.');
            console.error('Error fetching colleges:', err);
            setColleges([]);
            setTotalColleges(0);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchColleges();
    }, [filters, perPage, currentPage]); // Refetch when filters, perPage, or currentPage changes

    const handlePerPageChange = (e) => {
        setPerPage(Number(e.target.value));
        setCurrentPage(1); // Reset to the first page
    };

    const handlePageChange = (page) => {
      setCurrentPage(page);
      window.scrollTo({ top: 0, behavior: 'smooth' }); // Smooth scroll to the top
  };

    const totalPages = Math.ceil(totalColleges / perPage); // Calculate total pages

    // Calculate the range of pages to display
    const pageRange = () => {
        const startPage = Math.floor((currentPage - 1) / 4) * 4 + 1;
        const endPage = Math.min(startPage + 3, totalPages);
        return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    };

    return (
        <div className="college-search-container">
            <h1>Search Colleges</h1>

            <SearchBar 
                onSearch={handleSearch}
                initialValue={filters.name}
            />

            <div className="filters-section">
                <select 
                    name="country_id"
                    value={filters.country_id}
                    onChange={handleFilterChange}
                    className="filter-select"
                >
                    <option value="">Select Country</option>
                    <option value="253">Canada</option>
                    <option value="291">USA</option>
                </select>

                <input
                    type="text"
                    name="campus"
                    placeholder="Campus Location"
                    value={filters.campus}
                    onChange={handleFilterChange}
                    className="filter-input"
                />

                <select
                    name="intake"
                    value={filters.intake}
                    onChange={handleFilterChange}
                    className="filter-select"
                >
                    <option value="">Select Intake</option>
                    <option value="September-2025">September 2025</option>
                    <option value="January-2026">January 2026</option>
                </select>

                <input
                    type="text"
                    name="application_fee"
                    placeholder="Application Fee"
                    value={filters.application_fee}
                    onChange={handleFilterChange}
                    className="filter-input"
                />

                <select
                    value={perPage}
                    onChange={handlePerPageChange}
                    className="filter-select"
                >
                    <option value={10}>10 items per page</option>
                    <option value={20}>20 items per page</option>
                    <option value={50}>50 items per page</option>
                </select>
            </div>

            <div className="results-section">
                {loading && <div className="loading">Loading...</div>}
                {error && <div className="error">{error}</div>}
                
                {!loading && !error && colleges.length === 0 && (
                    <div className="no-results">No colleges found</div>
                )}

                {!loading && !error && colleges.length > 0 && colleges.map(college => (
                    <div key={college.id} className="college-card">
                        {college.image && (
                            <img 
                                src={`https://portal.universitybureau.com/${college.image}`}
                                alt={college.name}
                                className="college-image"
                            />
                        )}
                        <div className="college-info">
                            <h2>{college.name}</h2>
                            <p><strong>Campus:</strong> {college.campus}</p>
                            <p><strong>Intake:</strong> {college.intake}</p>
                            <p><strong>Application Fee:</strong> {college.application_fee}</p>
                            <p><strong>Level of Education:</strong> {college.level_of_edu}</p>
                            <p className="college-description">{college.description}</p>
                        </div>
                    </div>
                ))}

                {/* Display total number of colleges */}
                {!loading && !error && (
                    <div className="total-colleges">
                        Total Colleges: {totalColleges}
                    </div>
                )}

                {/* Pagination Controls */}
                {!loading && colleges.length > 0 && (
                    <div className="pagination">
                        <button 
                            onClick={() => handlePageChange(currentPage - 1)} 
                            disabled={currentPage === 1}
                        >
                            Prev
                        </button>
                        {pageRange().map(page => (
                            <button
                                key={page}
                                onClick={() => handlePageChange(page)}
                                className={`pagination-button ${currentPage === page ? 'pageactive' : ''}`}
                            >
                                {page}
                            </button>
                        ))}
                        <button 
                            onClick={() => handlePageChange(currentPage + 1)} 
                            disabled={currentPage === totalPages}
                        >
                            Next
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default CollegeSearch;