import React from "react";

function News() {
  return (
    <div>
      <div id="page" className="site site_wrapper">
        <div
          className="breadcrumb-area bg-default  has-breadcrumb-overlay pt-130 pb-130"
          data-background="https://themexriver.com/wp/kadu/wp-content/uploads/2024/06/breadcrumb-img-1.webp"
        >
          <div className="container kd-container-1">
            <div className="row">
              <div className="col-xxl-12">
                <div className="breadcrumb-wrap">
                  <h2 className="breadcrumb-title kd-heading-1 kd-font-900 kd-h2-title-ani kd-h2-split-text">
                    News
                  </h2>
                  <div className="breadcrumb-list wow fadeInUp">
                    <a href="https://themexriver.com/wp/kadu/">Home &nbsp;</a>
                    <span>News</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          data-elementor-type="wp-page"
          data-elementor-id={1982}
          className="elementor elementor-1982"
        >
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-ae027fb elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="ae027fb"
            data-element_type="section"
          >
            <div className="elementor-container elementor-column-gap-default">
              <div
                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d313c7d"
                data-id="d313c7d"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-element elementor-element-798e282 elementor-widget elementor-widget-go-s-heading"
                    data-id="798e282"
                    data-element_type="widget"
                    data-widget_type="go-s-heading.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="prthalign">
                        <h6 className="kd-subtitle-1 kd-subtitle-ani-1 elementor-kadu-sub">
                          <img
                            decoding="async"
                            src="https://themexriver.com/wp/kadu/wp-content/uploads/2024/05/star-1.webp"
                            alt=""
                          />
                          News
                        </h6>
                        <h1 className="elementor-gt-heading kd-section-title-1 kd-title-ani kd-split-text">
                          Lstest News
                        </h1>
                        <div
                          className="elementor-gt-desc kd-para-1 disc wow"
                          data-splitting=""
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-2fb8ee4 elementor-widget elementor-widget-go-event-id"
                    data-id="2fb8ee4"
                    data-element_type="widget"
                    data-widget_type="go-event-id.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="kd-event-5-item-wrap">
                        <div className="kd-event-5-item">
                          <h5 className="item-date kd-heading-1 kd-font-900">
                            66 <span>august, 2024</span>{" "}
                          </h5>
                          <div className="item-img fix img-cover">
                            <img
                              decoding="async"
                              src="https://themexriver.com/wp/kadu/wp-content/uploads/2024/06/e5-img-1.webp"
                              alt="List"
                            />
                          </div>
                          <div className="item-content">
                            <h4 className="item-title kd-heading-1 kd-font-700">
                              <a
                                href="https://themexriver.com/wp/kadu/event/event-details/"
                                aria-label="name"
                              >
                                Business English Programs
                              </a>
                            </h4>
                            <ul className="item-info kd-heading-1">
                              <li>
                                <i className="fas fa-location" />
                                Canada
                              </li>
                            </ul>
                            <p className="kd-para-1 item-disc">
                              Lorem ipsum dolor sit amet consectetur. Dolor amet
                              viverra lectus egestas velit massa tristique tortor.
                              Purus pellentesque id mattis turpis.
                            </p>
                          </div>
                          <div className="item-btn-wrap">
                            <a
                              href="https://themexriver.com/wp/kadu/event/event-details/"
                              aria-label="name"
                              className="kd-pr-btn-1 item-btn text-capitalize "
                            >
                              <span
                                className="text"
                                data-back="View"
                                data-front="View"
                              />
                              <span className="icon">
                                <i className="fa-solid fa-arrow-right" />
                              </span>
                            </a>
                          </div>
                        </div>
                        <div className="kd-event-5-item">
                          <h5 className="item-date kd-heading-1 kd-font-900">
                            20 <span>august, 2024</span>{" "}
                          </h5>
                          <div className="item-img fix img-cover">
                            <img
                              decoding="async"
                              src="https://themexriver.com/wp/kadu/wp-content/uploads/2024/06/e5-img-2.webp"
                              alt="List"
                            />
                          </div>
                          <div className="item-content">
                            <h4 className="item-title kd-heading-1 kd-font-700">
                              <a
                                href="https://themexriver.com/wp/kadu/event/event-details/"
                                aria-label="name"
                              >
                                Exactly Technology Can Make Reading
                              </a>
                            </h4>
                            <ul className="item-info kd-heading-1">
                              <li>
                                <i className="fas fa-location" />
                                USA
                              </li>
                            </ul>
                            <p className="kd-para-1 item-disc">
                              Lorem ipsum dolor sit amet consectetur. Dolor amet
                              viverra lectus egestas velit massa tristique tortor.
                              Purus pellentesque id mattis turpis.
                            </p>
                          </div>
                          <div className="item-btn-wrap">
                            <a
                              href="https://themexriver.com/wp/kadu/event/event-details/"
                              aria-label="name"
                              className="kd-pr-btn-1 item-btn text-capitalize "
                            >
                              <span
                                className="text"
                                data-back="View"
                                data-front="View"
                              />
                              <span className="icon">
                                <i className="fa-solid fa-arrow-right" />
                              </span>
                            </a>
                          </div>
                        </div>
                        <div className="kd-event-5-item">
                          <h5 className="item-date kd-heading-1 kd-font-900">
                            35 <span>august, 2024</span>{" "}
                          </h5>
                          <div className="item-img fix img-cover">
                            <img
                              decoding="async"
                              src="https://themexriver.com/wp/kadu/wp-content/uploads/2024/06/e5-img-3.webp"
                              alt="List"
                            />
                          </div>
                          <div className="item-content">
                            <h4 className="item-title kd-heading-1 kd-font-700">
                              <a
                                href="https://themexriver.com/wp/kadu/event/event-details/"
                                aria-label="name"
                              >
                                Begin Teaching Online Full Time
                              </a>
                            </h4>
                            <ul className="item-info kd-heading-1">
                              <li>
                                <i className="fas fa-location" />
                                Europe
                              </li>
                            </ul>
                            <p className="kd-para-1 item-disc">
                              Lorem ipsum dolor sit amet consectetur. Dolor amet
                              viverra lectus egestas velit massa tristique tortor.
                              Purus pellentesque id mattis turpis.
                            </p>
                          </div>
                          <div className="item-btn-wrap">
                            <a
                              href="https://themexriver.com/wp/kadu/event/event-details/"
                              aria-label="name"
                              className="kd-pr-btn-1 item-btn text-capitalize "
                            >
                              <span
                                className="text"
                                data-back="View"
                                data-front="View"
                              />
                              <span className="icon">
                                <i className="fa-solid fa-arrow-right" />
                              </span>
                            </a>
                          </div>
                        </div>
                        <div className="kd-event-5-item">
                          <h5 className="item-date kd-heading-1 kd-font-900">
                            11 <span>august, 2024</span>{" "}
                          </h5>
                          <div className="item-img fix img-cover">
                            <img
                              decoding="async"
                              src="https://themexriver.com/wp/kadu/wp-content/uploads/2024/06/e5-img-4.webp"
                              alt="List"
                            />
                          </div>
                          <div className="item-content">
                            <h4 className="item-title kd-heading-1 kd-font-700">
                              <a
                                href="https://themexriver.com/wp/kadu/event/event-details/"
                                aria-label="name"
                              >
                                English Can Make Reading
                              </a>
                            </h4>
                            <ul className="item-info kd-heading-1">
                              <li>
                                <i className="fas fa-location" />
                                USA
                              </li>
                            </ul>
                            <p className="kd-para-1 item-disc">
                              Lorem ipsum dolor sit amet consectetur. Dolor amet
                              viverra lectus egestas velit massa tristique tortor.
                              Purus pellentesque id mattis turpis.
                            </p>
                          </div>
                          <div className="item-btn-wrap">
                            <a
                              href="https://themexriver.com/wp/kadu/event/event-details/"
                              aria-label="name"
                              className="kd-pr-btn-1 item-btn text-capitalize "
                            >
                              <span
                                className="text"
                                data-back="View"
                                data-front="View"
                              />
                              <span className="icon">
                                <i className="fa-solid fa-arrow-right" />
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-afcb67e elementor-section-full_width elementor-section-height-default elementor-section-height-default"
          data-id="afcb67e"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
        >
          <div className="elementor-container elementor-column-gap-no">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-83136ad"
              data-id="83136ad"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-3f6bb02 elementor-widget elementor-widget-go-course-tab"
                  data-id="3f6bb02"
                  data-element_type="widget"
                  data-widget_type="go-course-tab.default"
                >
                  <div className="elementor-widget-container">
                    <div className="kd-course-1-area pt-130 pb-60 fix">
                      <div className="container kd-container-1">
                        {/* sectiont-title */}
                        <div className="kd-course-1-scn-title">
                          <div className="left">
                            <h6 className="kd-subtitle-1 kd-subtitle-ani-1 elementor-kadu-sub">
                              Get Updated
                            </h6>
                            <h1 className="elementor-gt-heading kd-section-title-1 kd-title-ani kd-split-text">
                              News with Us
                            </h1>
                          </div>
                          <div className="right txaa-fade-right">
                            <ul
                              className="kd-course-1-tabs-btn"
                              id="myTab"
                              role="tablist"
                            >
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link active kd-heading-1 kd-font-800"
                                  id="home-tab0"
                                  data-bs-toggle="tab"
                                  data-bs-target="#home0"
                                  type="button"
                                  role="tab"
                                  aria-controls="home0"
                                  aria-selected="true"
                                >
                                  Latest news
                                </button>
                              </li>
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link  kd-heading-1 kd-font-800"
                                  id="home-tab1"
                                  data-bs-toggle="tab"
                                  data-bs-target="#home1"
                                  type="button"
                                  role="tab"
                                  aria-controls="home1"
                                  aria-selected="true"
                                >
                                  Popular News
                                </button>
                              </li>
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link  kd-heading-1 kd-font-800"
                                  id="home-tab2"
                                  data-bs-toggle="tab"
                                  data-bs-target="#home2"
                                  type="button"
                                  role="tab"
                                  aria-controls="home2"
                                  aria-selected="true"
                                >
                                  Hot News
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                        {/* tabs-content */}
                        <div
                          className="tab-content kd-course-1-tabs-content-wrap"
                          id="myTabContent"
                        >
                          {/* sinlge-content-wrap */}
                          <div
                            className="tab-pane fade animated fadeInUp show active kd-course-1-tabs-content"
                            id="home0"
                            role="tabpanel"
                            aria-labelledby="home-tab0"
                          >
                            {/* slider */}
                            <div className="swiper-container fix kd-course-1-active">
                              <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                  <div className="kd-course-1-tabs-card fix">
                                    <div className="main-img">
                                      <img
                                        fetchpriority="high"
                                        decoding="async"
                                        width={352}
                                        height={235}
                                        src="https://themexriver.com/wp/kadu/wp-content/uploads/2024/05/painting-workshop-painting-students-enjoying-their-classes-1.webp"
                                        className="attachment-kadu-400x235 size-kadu-400x235 wp-post-image"
                                        alt=""
                                        srcSet="https://themexriver.com/wp/kadu/wp-content/uploads/2024/05/painting-workshop-painting-students-enjoying-their-classes-1.webp 1500w, https://themexriver.com/wp/kadu/wp-content/uploads/2024/05/painting-workshop-painting-students-enjoying-their-classes-1-600x400.webp 600w, https://themexriver.com/wp/kadu/wp-content/uploads/2024/05/painting-workshop-painting-students-enjoying-their-classes-1-300x200.webp 300w, https://themexriver.com/wp/kadu/wp-content/uploads/2024/05/painting-workshop-painting-students-enjoying-their-classes-1-1024x683.webp 1024w, https://themexriver.com/wp/kadu/wp-content/uploads/2024/05/painting-workshop-painting-students-enjoying-their-classes-1-768x513.webp 768w"
                                        sizes="(max-width: 352px) 100vw, 352px"
                                      />
                                    </div>
                                    <h4 className="price kd-heading-1 kd-font-900">
                                      $15
                                    </h4>
                                    <div className="content">
                                      <h5 className="title kd-heading-1 kd-font-900">
                                        <a
                                          href="https://themexriver.com/wp/kadu/courses/course-one/"
                                          aria-label="name"
                                        >
                                          Graphic Design Master Class Learn GREAT
                                        </a>
                                      </h5>
                                      <ul className="kd-course-1-tabs-card-feature kd-heading-1 kd-font-800">
                                        <li>1 Lessons</li>
                                        <li>1 week</li>
                                      </ul>
                                      <p className="kd-para-1 disc">
                                        Awesome hexagon themed stream pack, you can
                                        change
                                      </p>
                                    </div>
                                    <span className="line d-block" />
                                    <div className="card-bottom">
                                      <div className="left">
                                        <div className="kd-course-1-tabs-card-author">
                                          <div className="author-img img-cover fix">
                                            <img
                                              alt=""
                                              src="https://secure.gravatar.com/avatar/12e929e2ea1121d17f4d162a5c3d50c5?s=35&d=mm&r=g"
                                              srcSet="https://secure.gravatar.com/avatar/12e929e2ea1121d17f4d162a5c3d50c5?s=70&d=mm&r=g 2x"
                                              className="avatar avatar-35 photo"
                                              height={35}
                                              width={35}
                                            />
                                          </div>
                                          <div className="author-content">
                                            <h6 className="post kd-heading-1 kd-font-500">
                                              posted by
                                            </h6>
                                            <h5 className="name kd-heading-1 kd-font-900">
                                              Marina Valentine
                                            </h5>
                                          </div>
                                        </div>
                                      </div>
                                      <span className="line-2 d-block" />
                                      <div className="right">
                                        <h5 className="student-count kd-heading-1 kd-font-800">
                                          3 Students{" "}
                                        </h5>
                                        <div className="kd-ratting-1 d-flex align-items-center flex-wrap">
                                          <div className="tutor-ratings-stars">
                                            <i
                                              className="tutor-icon-star-bold"
                                              data-rating-value={1}
                                            />
                                            <i
                                              className="tutor-icon-star-bold"
                                              data-rating-value={2}
                                            />
                                            <i
                                              className="tutor-icon-star-bold"
                                              data-rating-value={3}
                                            />
                                            <i
                                              className="tutor-icon-star-bold"
                                              data-rating-value={4}
                                            />
                                            <i
                                              className="tutor-icon-star-line"
                                              data-rating-value={5}
                                            />
                                          </div>
                                          <input
                                            type="hidden"
                                            name="tutor_rating_gen_input"
                                            defaultValue="4.33"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="swiper-slide">
                                  <div className="kd-course-1-tabs-card fix">
                                    <div className="main-img">
                                      <img
                                        decoding="async"
                                        width={400}
                                        height={235}
                                        src="https://themexriver.com/wp/kadu/wp-content/uploads/2024/06/education-400x235.webp"
                                        className="attachment-kadu-400x235 size-kadu-400x235 wp-post-image"
                                        alt=""
                                      />
                                    </div>
                                    <h4 className="price kd-heading-1 kd-font-900">
                                      $59
                                    </h4>
                                    <div className="content">
                                      <h5 className="title kd-heading-1 kd-font-900">
                                        <a
                                          href="https://themexriver.com/wp/kadu/courses/become-a-certified-web-developer-html-css-and-javascript/"
                                          aria-label="name"
                                        >
                                          Become a Certified Web Developer: HTML,
                                        </a>
                                      </h5>
                                      <ul className="kd-course-1-tabs-card-feature kd-heading-1 kd-font-800">
                                        <li>1 Lessons</li>
                                        <li>1 week</li>
                                      </ul>
                                      <p className="kd-para-1 disc">
                                        Awesome hexagon themed stream pack, you can
                                        change
                                      </p>
                                    </div>
                                    <span className="line d-block" />
                                    <div className="card-bottom">
                                      <div className="left">
                                        <div className="kd-course-1-tabs-card-author">
                                          <div className="author-img img-cover fix">
                                            <img
                                              alt=""
                                              src="https://secure.gravatar.com/avatar/12e929e2ea1121d17f4d162a5c3d50c5?s=35&d=mm&r=g"
                                              srcSet="https://secure.gravatar.com/avatar/12e929e2ea1121d17f4d162a5c3d50c5?s=70&d=mm&r=g 2x"
                                              className="avatar avatar-35 photo"
                                              height={35}
                                              width={35}
                                            />
                                          </div>
                                          <div className="author-content">
                                            <h6 className="post kd-heading-1 kd-font-500">
                                              posted by
                                            </h6>
                                            <h5 className="name kd-heading-1 kd-font-900">
                                              Marina Valentine
                                            </h5>
                                          </div>
                                        </div>
                                      </div>
                                      <span className="line-2 d-block" />
                                      <div className="right">
                                        <h5 className="student-count kd-heading-1 kd-font-800">
                                          0 Students{" "}
                                        </h5>
                                        <div className="kd-ratting-1 d-flex align-items-center flex-wrap">
                                          <div className="tutor-ratings-stars">
                                            <i
                                              className="tutor-icon-star-line"
                                              data-rating-value={1}
                                            />
                                            <i
                                              className="tutor-icon-star-line"
                                              data-rating-value={2}
                                            />
                                            <i
                                              className="tutor-icon-star-line"
                                              data-rating-value={3}
                                            />
                                            <i
                                              className="tutor-icon-star-line"
                                              data-rating-value={4}
                                            />
                                            <i
                                              className="tutor-icon-star-line"
                                              data-rating-value={5}
                                            />
                                          </div>
                                          <input
                                            type="hidden"
                                            name="tutor_rating_gen_input"
                                            defaultValue={0}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="swiper-slide">
                                  <div className="kd-course-1-tabs-card fix">
                                    <div className="main-img">
                                      <img
                                        decoding="async"
                                        width={400}
                                        height={235}
                                        src="https://themexriver.com/wp/kadu/wp-content/uploads/2024/06/creativity-ideas-design-thought-bubble-icon-concept-400x235.webp"
                                        className="attachment-kadu-400x235 size-kadu-400x235 wp-post-image"
                                        alt=""
                                      />
                                    </div>
                                    <h4 className="price kd-heading-1 kd-font-900">
                                      Free
                                    </h4>
                                    <div className="content">
                                      <h5 className="title kd-heading-1 kd-font-900">
                                        <a
                                          href="https://themexriver.com/wp/kadu/courses/pixel-art-mastery-course-beginner-to-pixel-art-professional/"
                                          aria-label="name"
                                        >
                                          Pixel Art Mastery Course: Beginner To
                                        </a>
                                      </h5>
                                      <ul className="kd-course-1-tabs-card-feature kd-heading-1 kd-font-800">
                                        <li>0 Lessons</li>
                                        <li>0 week</li>
                                      </ul>
                                      <p className="kd-para-1 disc">
                                        Awesome hexagon themed stream pack, you can
                                        change
                                      </p>
                                    </div>
                                    <span className="line d-block" />
                                    <div className="card-bottom">
                                      <div className="left">
                                        <div className="kd-course-1-tabs-card-author">
                                          <div className="author-img img-cover fix">
                                            <img
                                              alt=""
                                              src="https://secure.gravatar.com/avatar/12e929e2ea1121d17f4d162a5c3d50c5?s=35&d=mm&r=g"
                                              srcSet="https://secure.gravatar.com/avatar/12e929e2ea1121d17f4d162a5c3d50c5?s=70&d=mm&r=g 2x"
                                              className="avatar avatar-35 photo"
                                              height={35}
                                              width={35}
                                            />
                                          </div>
                                          <div className="author-content">
                                            <h6 className="post kd-heading-1 kd-font-500">
                                              posted by
                                            </h6>
                                            <h5 className="name kd-heading-1 kd-font-900">
                                              Marina Valentine
                                            </h5>
                                          </div>
                                        </div>
                                      </div>
                                      <span className="line-2 d-block" />
                                      <div className="right">
                                        <h5 className="student-count kd-heading-1 kd-font-800">
                                          22 Students{" "}
                                        </h5>
                                        <div className="kd-ratting-1 d-flex align-items-center flex-wrap">
                                          <div className="tutor-ratings-stars">
                                            <i
                                              className="tutor-icon-star-bold"
                                              data-rating-value={1}
                                            />
                                            <i
                                              className="tutor-icon-star-bold"
                                              data-rating-value={2}
                                            />
                                            <i
                                              className="tutor-icon-star-bold"
                                              data-rating-value={3}
                                            />
                                            <i
                                              className="tutor-icon-star-bold"
                                              data-rating-value={4}
                                            />
                                            <i
                                              className="tutor-icon-star-half-bold"
                                              data-rating-value={5}
                                            />
                                          </div>
                                          <input
                                            type="hidden"
                                            name="tutor_rating_gen_input"
                                            defaultValue="4.67"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* slider-btn */}
                            <div className="kd-course-1-tabs-slider-btn">
                              <div className="kd_course_1_slider_prev">
                                <div className="kd-slider-btn-1 d-flex align-items-center justify-content-center">
                                  <span className="icon-1">
                                    <i className="fa-solid fa-arrow-left" />
                                  </span>
                                  <span className="icon-2">
                                    <i className="fa-solid fa-arrow-left" />
                                  </span>
                                </div>
                              </div>
                              <div className="kd_course_1_slider_next">
                                <div className="kd-slider-btn-1 d-flex align-items-center justify-content-center has-next">
                                  <span className="icon-1">
                                    <i className="fa-solid fa-arrow-right" />
                                  </span>
                                  <span className="icon-2">
                                    <i className="fa-solid fa-arrow-right" />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade animated fadeInUp  kd-course-1-tabs-content"
                            id="home1"
                            role="tabpanel"
                            aria-labelledby="home-tab1"
                          >
                            {/* slider */}
                            <div className="swiper-container fix kd-course-1-active">
                              <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                  <div className="kd-course-1-tabs-card fix">
                                    <div className="main-img">
                                      <img
                                        loading="lazy"
                                        decoding="async"
                                        width={400}
                                        height={235}
                                        src="https://themexriver.com/wp/kadu/wp-content/uploads/2024/06/development-400x235.webp"
                                        className="attachment-kadu-400x235 size-kadu-400x235 wp-post-image"
                                        alt=""
                                      />
                                    </div>
                                    <h4 className="price kd-heading-1 kd-font-900">
                                      $49
                                    </h4>
                                    <div className="content">
                                      <h5 className="title kd-heading-1 kd-font-900">
                                        <a
                                          href="https://themexriver.com/wp/kadu/courses/it-fundamentals-everything-you-need-to-know-about-it/"
                                          aria-label="name"
                                        >
                                          IT Fundamentals – Everything you need
                                        </a>
                                      </h5>
                                      <ul className="kd-course-1-tabs-card-feature kd-heading-1 kd-font-800">
                                        <li>0 Lessons</li>
                                        <li>0 week</li>
                                      </ul>
                                      <p className="kd-para-1 disc" />
                                    </div>
                                    <span className="line d-block" />
                                    <div className="card-bottom">
                                      <div className="left">
                                        <div className="kd-course-1-tabs-card-author">
                                          <div className="author-img img-cover fix">
                                            <img
                                              alt=""
                                              src="https://secure.gravatar.com/avatar/12e929e2ea1121d17f4d162a5c3d50c5?s=35&d=mm&r=g"
                                              srcSet="https://secure.gravatar.com/avatar/12e929e2ea1121d17f4d162a5c3d50c5?s=70&d=mm&r=g 2x"
                                              className="avatar avatar-35 photo"
                                              height={35}
                                              width={35}
                                            />
                                          </div>
                                          <div className="author-content">
                                            <h6 className="post kd-heading-1 kd-font-500">
                                              posted by
                                            </h6>
                                            <h5 className="name kd-heading-1 kd-font-900">
                                              Marina Valentine
                                            </h5>
                                          </div>
                                        </div>
                                      </div>
                                      <span className="line-2 d-block" />
                                      <div className="right">
                                        <h5 className="student-count kd-heading-1 kd-font-800">
                                          0 Students{" "}
                                        </h5>
                                        <div className="kd-ratting-1 d-flex align-items-center flex-wrap">
                                          <div className="tutor-ratings-stars">
                                            <i
                                              className="tutor-icon-star-line"
                                              data-rating-value={1}
                                            />
                                            <i
                                              className="tutor-icon-star-line"
                                              data-rating-value={2}
                                            />
                                            <i
                                              className="tutor-icon-star-line"
                                              data-rating-value={3}
                                            />
                                            <i
                                              className="tutor-icon-star-line"
                                              data-rating-value={4}
                                            />
                                            <i
                                              className="tutor-icon-star-line"
                                              data-rating-value={5}
                                            />
                                          </div>
                                          <input
                                            type="hidden"
                                            name="tutor_rating_gen_input"
                                            defaultValue={0}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="swiper-slide">
                                  <div className="kd-course-1-tabs-card fix">
                                    <div className="main-img">
                                      <img
                                        loading="lazy"
                                        decoding="async"
                                        width={400}
                                        height={235}
                                        src="https://themexriver.com/wp/kadu/wp-content/uploads/2024/06/digital-marketing-400x235.webp"
                                        className="attachment-kadu-400x235 size-kadu-400x235 wp-post-image"
                                        alt=""
                                      />
                                    </div>
                                    <h4 className="price kd-heading-1 kd-font-900">
                                      $29
                                    </h4>
                                    <div className="content">
                                      <h5 className="title kd-heading-1 kd-font-900">
                                        <a
                                          href="https://themexriver.com/wp/kadu/courses/digital-marketing-masterclass-ai-and-chatgpt-for-marketing/"
                                          aria-label="name"
                                        >
                                          Digital Marketing Masterclass + AI and
                                        </a>
                                      </h5>
                                      <ul className="kd-course-1-tabs-card-feature kd-heading-1 kd-font-800">
                                        <li>0 Lessons</li>
                                        <li>0 week</li>
                                      </ul>
                                      <p className="kd-para-1 disc" />
                                    </div>
                                    <span className="line d-block" />
                                    <div className="card-bottom">
                                      <div className="left">
                                        <div className="kd-course-1-tabs-card-author">
                                          <div className="author-img img-cover fix">
                                            <img
                                              alt=""
                                              src="https://secure.gravatar.com/avatar/12e929e2ea1121d17f4d162a5c3d50c5?s=35&d=mm&r=g"
                                              srcSet="https://secure.gravatar.com/avatar/12e929e2ea1121d17f4d162a5c3d50c5?s=70&d=mm&r=g 2x"
                                              className="avatar avatar-35 photo"
                                              height={35}
                                              width={35}
                                            />
                                          </div>
                                          <div className="author-content">
                                            <h6 className="post kd-heading-1 kd-font-500">
                                              posted by
                                            </h6>
                                            <h5 className="name kd-heading-1 kd-font-900">
                                              Marina Valentine
                                            </h5>
                                          </div>
                                        </div>
                                      </div>
                                      <span className="line-2 d-block" />
                                      <div className="right">
                                        <h5 className="student-count kd-heading-1 kd-font-800">
                                          0 Students{" "}
                                        </h5>
                                        <div className="kd-ratting-1 d-flex align-items-center flex-wrap">
                                          <div className="tutor-ratings-stars">
                                            <i
                                              className="tutor-icon-star-line"
                                              data-rating-value={1}
                                            />
                                            <i
                                              className="tutor-icon-star-line"
                                              data-rating-value={2}
                                            />
                                            <i
                                              className="tutor-icon-star-line"
                                              data-rating-value={3}
                                            />
                                            <i
                                              className="tutor-icon-star-line"
                                              data-rating-value={4}
                                            />
                                            <i
                                              className="tutor-icon-star-line"
                                              data-rating-value={5}
                                            />
                                          </div>
                                          <input
                                            type="hidden"
                                            name="tutor_rating_gen_input"
                                            defaultValue={0}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="swiper-slide">
                                  <div className="kd-course-1-tabs-card fix">
                                    <div className="main-img">
                                      <img
                                        loading="lazy"
                                        decoding="async"
                                        width={400}
                                        height={235}
                                        src="https://themexriver.com/wp/kadu/wp-content/uploads/2024/06/design-400x235.webp"
                                        className="attachment-kadu-400x235 size-kadu-400x235 wp-post-image"
                                        alt=""
                                      />
                                    </div>
                                    <h4 className="price kd-heading-1 kd-font-900">
                                      Free
                                    </h4>
                                    <div className="content">
                                      <h5 className="title kd-heading-1 kd-font-900">
                                        <a
                                          href="https://themexriver.com/wp/kadu/courses/graphic-design-masterclass-learn-great-design/"
                                          aria-label="name"
                                        >
                                          Graphic Design Master Class – Learn
                                        </a>
                                      </h5>
                                      <ul className="kd-course-1-tabs-card-feature kd-heading-1 kd-font-800">
                                        <li>0 Lessons</li>
                                        <li>0 week</li>
                                      </ul>
                                      <p className="kd-para-1 disc" />
                                    </div>
                                    <span className="line d-block" />
                                    <div className="card-bottom">
                                      <div className="left">
                                        <div className="kd-course-1-tabs-card-author">
                                          <div className="author-img img-cover fix">
                                            <img
                                              alt=""
                                              src="https://secure.gravatar.com/avatar/12e929e2ea1121d17f4d162a5c3d50c5?s=35&d=mm&r=g"
                                              srcSet="https://secure.gravatar.com/avatar/12e929e2ea1121d17f4d162a5c3d50c5?s=70&d=mm&r=g 2x"
                                              className="avatar avatar-35 photo"
                                              height={35}
                                              width={35}
                                            />
                                          </div>
                                          <div className="author-content">
                                            <h6 className="post kd-heading-1 kd-font-500">
                                              posted by
                                            </h6>
                                            <h5 className="name kd-heading-1 kd-font-900">
                                              Marina Valentine
                                            </h5>
                                          </div>
                                        </div>
                                      </div>
                                      <span className="line-2 d-block" />
                                      <div className="right">
                                        <h5 className="student-count kd-heading-1 kd-font-800">
                                          3 Students{" "}
                                        </h5>
                                        <div className="kd-ratting-1 d-flex align-items-center flex-wrap">
                                          <div className="tutor-ratings-stars">
                                            <i
                                              className="tutor-icon-star-line"
                                              data-rating-value={1}
                                            />
                                            <i
                                              className="tutor-icon-star-line"
                                              data-rating-value={2}
                                            />
                                            <i
                                              className="tutor-icon-star-line"
                                              data-rating-value={3}
                                            />
                                            <i
                                              className="tutor-icon-star-line"
                                              data-rating-value={4}
                                            />
                                            <i
                                              className="tutor-icon-star-line"
                                              data-rating-value={5}
                                            />
                                          </div>
                                          <input
                                            type="hidden"
                                            name="tutor_rating_gen_input"
                                            defaultValue={0}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* slider-btn */}
                            <div className="kd-course-1-tabs-slider-btn">
                              <div className="kd_course_1_slider_prev">
                                <div className="kd-slider-btn-1 d-flex align-items-center justify-content-center">
                                  <span className="icon-1">
                                    <i className="fa-solid fa-arrow-left" />
                                  </span>
                                  <span className="icon-2">
                                    <i className="fa-solid fa-arrow-left" />
                                  </span>
                                </div>
                              </div>
                              <div className="kd_course_1_slider_next">
                                <div className="kd-slider-btn-1 d-flex align-items-center justify-content-center has-next">
                                  <span className="icon-1">
                                    <i className="fa-solid fa-arrow-right" />
                                  </span>
                                  <span className="icon-2">
                                    <i className="fa-solid fa-arrow-right" />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade animated fadeInUp  kd-course-1-tabs-content"
                            id="home2"
                            role="tabpanel"
                            aria-labelledby="home-tab2"
                          >
                            {/* slider */}
                            <div className="swiper-container fix kd-course-1-active">
                              <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                  <div className="kd-course-1-tabs-card fix">
                                    <div className="main-img">
                                      <img
                                        decoding="async"
                                        width={400}
                                        height={235}
                                        src="https://themexriver.com/wp/kadu/wp-content/uploads/2024/06/education-400x235.webp"
                                        className="attachment-kadu-400x235 size-kadu-400x235 wp-post-image"
                                        alt=""
                                      />
                                    </div>
                                    <h4 className="price kd-heading-1 kd-font-900">
                                      $59
                                    </h4>
                                    <div className="content">
                                      <h5 className="title kd-heading-1 kd-font-900">
                                        <a
                                          href="https://themexriver.com/wp/kadu/courses/become-a-certified-web-developer-html-css-and-javascript/"
                                          aria-label="name"
                                        >
                                          Become a Certified Web Developer: HTML,
                                        </a>
                                      </h5>
                                      <ul className="kd-course-1-tabs-card-feature kd-heading-1 kd-font-800">
                                        <li>1 Lessons</li>
                                        <li>1 week</li>
                                      </ul>
                                      <p className="kd-para-1 disc">
                                        Awesome hexagon themed stream pack, you can
                                        change
                                      </p>
                                    </div>
                                    <span className="line d-block" />
                                    <div className="card-bottom">
                                      <div className="left">
                                        <div className="kd-course-1-tabs-card-author">
                                          <div className="author-img img-cover fix">
                                            <img
                                              alt=""
                                              src="https://secure.gravatar.com/avatar/12e929e2ea1121d17f4d162a5c3d50c5?s=35&d=mm&r=g"
                                              srcSet="https://secure.gravatar.com/avatar/12e929e2ea1121d17f4d162a5c3d50c5?s=70&d=mm&r=g 2x"
                                              className="avatar avatar-35 photo"
                                              height={35}
                                              width={35}
                                            />
                                          </div>
                                          <div className="author-content">
                                            <h6 className="post kd-heading-1 kd-font-500">
                                              posted by
                                            </h6>
                                            <h5 className="name kd-heading-1 kd-font-900">
                                              Marina Valentine
                                            </h5>
                                          </div>
                                        </div>
                                      </div>
                                      <span className="line-2 d-block" />
                                      <div className="right">
                                        <h5 className="student-count kd-heading-1 kd-font-800">
                                          0 Students{" "}
                                        </h5>
                                        <div className="kd-ratting-1 d-flex align-items-center flex-wrap">
                                          <div className="tutor-ratings-stars">
                                            <i
                                              className="tutor-icon-star-line"
                                              data-rating-value={1}
                                            />
                                            <i
                                              className="tutor-icon-star-line"
                                              data-rating-value={2}
                                            />
                                            <i
                                              className="tutor-icon-star-line"
                                              data-rating-value={3}
                                            />
                                            <i
                                              className="tutor-icon-star-line"
                                              data-rating-value={4}
                                            />
                                            <i
                                              className="tutor-icon-star-line"
                                              data-rating-value={5}
                                            />
                                          </div>
                                          <input
                                            type="hidden"
                                            name="tutor_rating_gen_input"
                                            defaultValue={0}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="swiper-slide">
                                  <div className="kd-course-1-tabs-card fix">
                                    <div className="main-img">
                                      <img
                                        loading="lazy"
                                        decoding="async"
                                        width={400}
                                        height={235}
                                        src="https://themexriver.com/wp/kadu/wp-content/uploads/2024/06/development-400x235.webp"
                                        className="attachment-kadu-400x235 size-kadu-400x235 wp-post-image"
                                        alt=""
                                      />
                                    </div>
                                    <h4 className="price kd-heading-1 kd-font-900">
                                      $49
                                    </h4>
                                    <div className="content">
                                      <h5 className="title kd-heading-1 kd-font-900">
                                        <a
                                          href="https://themexriver.com/wp/kadu/courses/it-fundamentals-everything-you-need-to-know-about-it/"
                                          aria-label="name"
                                        >
                                          IT Fundamentals – Everything you need
                                        </a>
                                      </h5>
                                      <ul className="kd-course-1-tabs-card-feature kd-heading-1 kd-font-800">
                                        <li>0 Lessons</li>
                                        <li>0 week</li>
                                      </ul>
                                      <p className="kd-para-1 disc" />
                                    </div>
                                    <span className="line d-block" />
                                    <div className="card-bottom">
                                      <div className="left">
                                        <div className="kd-course-1-tabs-card-author">
                                          <div className="author-img img-cover fix">
                                            <img
                                              alt=""
                                              src="https://secure.gravatar.com/avatar/12e929e2ea1121d17f4d162a5c3d50c5?s=35&d=mm&r=g"
                                              srcSet="https://secure.gravatar.com/avatar/12e929e2ea1121d17f4d162a5c3d50c5?s=70&d=mm&r=g 2x"
                                              className="avatar avatar-35 photo"
                                              height={35}
                                              width={35}
                                            />
                                          </div>
                                          <div className="author-content">
                                            <h6 className="post kd-heading-1 kd-font-500">
                                              posted by
                                            </h6>
                                            <h5 className="name kd-heading-1 kd-font-900">
                                              Marina Valentine
                                            </h5>
                                          </div>
                                        </div>
                                      </div>
                                      <span className="line-2 d-block" />
                                      <div className="right">
                                        <h5 className="student-count kd-heading-1 kd-font-800">
                                          0 Students{" "}
                                        </h5>
                                        <div className="kd-ratting-1 d-flex align-items-center flex-wrap">
                                          <div className="tutor-ratings-stars">
                                            <i
                                              className="tutor-icon-star-line"
                                              data-rating-value={1}
                                            />
                                            <i
                                              className="tutor-icon-star-line"
                                              data-rating-value={2}
                                            />
                                            <i
                                              className="tutor-icon-star-line"
                                              data-rating-value={3}
                                            />
                                            <i
                                              className="tutor-icon-star-line"
                                              data-rating-value={4}
                                            />
                                            <i
                                              className="tutor-icon-star-line"
                                              data-rating-value={5}
                                            />
                                          </div>
                                          <input
                                            type="hidden"
                                            name="tutor_rating_gen_input"
                                            defaultValue={0}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="swiper-slide">
                                  <div className="kd-course-1-tabs-card fix">
                                    <div className="main-img">
                                      <img
                                        loading="lazy"
                                        decoding="async"
                                        width={400}
                                        height={235}
                                        src="https://themexriver.com/wp/kadu/wp-content/uploads/2024/06/design-400x235.webp"
                                        className="attachment-kadu-400x235 size-kadu-400x235 wp-post-image"
                                        alt=""
                                      />
                                    </div>
                                    <h4 className="price kd-heading-1 kd-font-900">
                                      Free
                                    </h4>
                                    <div className="content">
                                      <h5 className="title kd-heading-1 kd-font-900">
                                        <a
                                          href="https://themexriver.com/wp/kadu/courses/graphic-design-masterclass-learn-great-design/"
                                          aria-label="name"
                                        >
                                          Graphic Design Master Class – Learn
                                        </a>
                                      </h5>
                                      <ul className="kd-course-1-tabs-card-feature kd-heading-1 kd-font-800">
                                        <li>0 Lessons</li>
                                        <li>0 week</li>
                                      </ul>
                                      <p className="kd-para-1 disc" />
                                    </div>
                                    <span className="line d-block" />
                                    <div className="card-bottom">
                                      <div className="left">
                                        <div className="kd-course-1-tabs-card-author">
                                          <div className="author-img img-cover fix">
                                            <img
                                              alt=""
                                              src="https://secure.gravatar.com/avatar/12e929e2ea1121d17f4d162a5c3d50c5?s=35&d=mm&r=g"
                                              srcSet="https://secure.gravatar.com/avatar/12e929e2ea1121d17f4d162a5c3d50c5?s=70&d=mm&r=g 2x"
                                              className="avatar avatar-35 photo"
                                              height={35}
                                              width={35}
                                            />
                                          </div>
                                          <div className="author-content">
                                            <h6 className="post kd-heading-1 kd-font-500">
                                              posted by
                                            </h6>
                                            <h5 className="name kd-heading-1 kd-font-900">
                                              Marina Valentine
                                            </h5>
                                          </div>
                                        </div>
                                      </div>
                                      <span className="line-2 d-block" />
                                      <div className="right">
                                        <h5 className="student-count kd-heading-1 kd-font-800">
                                          3 Students{" "}
                                        </h5>
                                        <div className="kd-ratting-1 d-flex align-items-center flex-wrap">
                                          <div className="tutor-ratings-stars">
                                            <i
                                              className="tutor-icon-star-line"
                                              data-rating-value={1}
                                            />
                                            <i
                                              className="tutor-icon-star-line"
                                              data-rating-value={2}
                                            />
                                            <i
                                              className="tutor-icon-star-line"
                                              data-rating-value={3}
                                            />
                                            <i
                                              className="tutor-icon-star-line"
                                              data-rating-value={4}
                                            />
                                            <i
                                              className="tutor-icon-star-line"
                                              data-rating-value={5}
                                            />
                                          </div>
                                          <input
                                            type="hidden"
                                            name="tutor_rating_gen_input"
                                            defaultValue={0}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="swiper-slide">
                                  <div className="kd-course-1-tabs-card fix">
                                    <div className="main-img">
                                      <img
                                        loading="lazy"
                                        decoding="async"
                                        width={400}
                                        height={235}
                                        src="https://themexriver.com/wp/kadu/wp-content/uploads/2024/06/design-1-400x235.webp"
                                        className="attachment-kadu-400x235 size-kadu-400x235 wp-post-image"
                                        alt=""
                                      />
                                    </div>
                                    <h4 className="price kd-heading-1 kd-font-900">
                                      $139
                                    </h4>
                                    <div className="content">
                                      <h5 className="title kd-heading-1 kd-font-900">
                                        <a
                                          href="https://themexriver.com/wp/kadu/courses/100-days-of-code-2024-web-development-bootcamp/"
                                          aria-label="name"
                                        >
                                          100 Days Of Code – 2024
                                        </a>
                                      </h5>
                                      <ul className="kd-course-1-tabs-card-feature kd-heading-1 kd-font-800">
                                        <li>0 Lessons</li>
                                        <li>0 week</li>
                                      </ul>
                                      <p className="kd-para-1 disc">
                                        Awesome hexagon themed stream pack, you can
                                        change
                                      </p>
                                    </div>
                                    <span className="line d-block" />
                                    <div className="card-bottom">
                                      <div className="left">
                                        <div className="kd-course-1-tabs-card-author">
                                          <div className="author-img img-cover fix">
                                            <img
                                              alt=""
                                              src="https://secure.gravatar.com/avatar/12e929e2ea1121d17f4d162a5c3d50c5?s=35&d=mm&r=g"
                                              srcSet="https://secure.gravatar.com/avatar/12e929e2ea1121d17f4d162a5c3d50c5?s=70&d=mm&r=g 2x"
                                              className="avatar avatar-35 photo"
                                              height={35}
                                              width={35}
                                            />
                                          </div>
                                          <div className="author-content">
                                            <h6 className="post kd-heading-1 kd-font-500">
                                              posted by
                                            </h6>
                                            <h5 className="name kd-heading-1 kd-font-900">
                                              Marina Valentine
                                            </h5>
                                          </div>
                                        </div>
                                      </div>
                                      <span className="line-2 d-block" />
                                      <div className="right">
                                        <h5 className="student-count kd-heading-1 kd-font-800">
                                          0 Students{" "}
                                        </h5>
                                        <div className="kd-ratting-1 d-flex align-items-center flex-wrap">
                                          <div className="tutor-ratings-stars">
                                            <i
                                              className="tutor-icon-star-line"
                                              data-rating-value={1}
                                            />
                                            <i
                                              className="tutor-icon-star-line"
                                              data-rating-value={2}
                                            />
                                            <i
                                              className="tutor-icon-star-line"
                                              data-rating-value={3}
                                            />
                                            <i
                                              className="tutor-icon-star-line"
                                              data-rating-value={4}
                                            />
                                            <i
                                              className="tutor-icon-star-line"
                                              data-rating-value={5}
                                            />
                                          </div>
                                          <input
                                            type="hidden"
                                            name="tutor_rating_gen_input"
                                            defaultValue={0}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="swiper-slide">
                                  <div className="kd-course-1-tabs-card fix">
                                    <div className="main-img">
                                      <img
                                        loading="lazy"
                                        decoding="async"
                                        width={400}
                                        height={235}
                                        src="https://themexriver.com/wp/kadu/wp-content/uploads/2024/06/program-400x235.webp"
                                        className="attachment-kadu-400x235 size-kadu-400x235 wp-post-image"
                                        alt=""
                                      />
                                    </div>
                                    <h4 className="price kd-heading-1 kd-font-900">
                                      $29
                                    </h4>
                                    <div className="content">
                                      <h5 className="title kd-heading-1 kd-font-900">
                                        <a
                                          href="https://themexriver.com/wp/kadu/courses/the-full-stack-web-development-mern-stack/"
                                          aria-label="name"
                                        >
                                          The Full Stack Web Development MERN
                                        </a>
                                      </h5>
                                      <ul className="kd-course-1-tabs-card-feature kd-heading-1 kd-font-800">
                                        <li>0 Lessons</li>
                                        <li>0 week</li>
                                      </ul>
                                      <p className="kd-para-1 disc">
                                        Awesome hexagon themed stream pack, you can
                                        change
                                      </p>
                                    </div>
                                    <span className="line d-block" />
                                    <div className="card-bottom">
                                      <div className="left">
                                        <div className="kd-course-1-tabs-card-author">
                                          <div className="author-img img-cover fix">
                                            <img
                                              alt=""
                                              src="https://secure.gravatar.com/avatar/12e929e2ea1121d17f4d162a5c3d50c5?s=35&d=mm&r=g"
                                              srcSet="https://secure.gravatar.com/avatar/12e929e2ea1121d17f4d162a5c3d50c5?s=70&d=mm&r=g 2x"
                                              className="avatar avatar-35 photo"
                                              height={35}
                                              width={35}
                                            />
                                          </div>
                                          <div className="author-content">
                                            <h6 className="post kd-heading-1 kd-font-500">
                                              posted by
                                            </h6>
                                            <h5 className="name kd-heading-1 kd-font-900">
                                              Marina Valentine
                                            </h5>
                                          </div>
                                        </div>
                                      </div>
                                      <span className="line-2 d-block" />
                                      <div className="right">
                                        <h5 className="student-count kd-heading-1 kd-font-800">
                                          0 Students{" "}
                                        </h5>
                                        <div className="kd-ratting-1 d-flex align-items-center flex-wrap">
                                          <div className="tutor-ratings-stars">
                                            <i
                                              className="tutor-icon-star-line"
                                              data-rating-value={1}
                                            />
                                            <i
                                              className="tutor-icon-star-line"
                                              data-rating-value={2}
                                            />
                                            <i
                                              className="tutor-icon-star-line"
                                              data-rating-value={3}
                                            />
                                            <i
                                              className="tutor-icon-star-line"
                                              data-rating-value={4}
                                            />
                                            <i
                                              className="tutor-icon-star-line"
                                              data-rating-value={5}
                                            />
                                          </div>
                                          <input
                                            type="hidden"
                                            name="tutor_rating_gen_input"
                                            defaultValue={0}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* slider-btn */}
                            <div className="kd-course-1-tabs-slider-btn">
                              <div className="kd_course_1_slider_prev">
                                <div className="kd-slider-btn-1 d-flex align-items-center justify-content-center">
                                  <span className="icon-1">
                                    <i className="fa-solid fa-arrow-left" />
                                  </span>
                                  <span className="icon-2">
                                    <i className="fa-solid fa-arrow-left" />
                                  </span>
                                </div>
                              </div>
                              <div className="kd_course_1_slider_next">
                                <div className="kd-slider-btn-1 d-flex align-items-center justify-content-center has-next">
                                  <span className="icon-1">
                                    <i className="fa-solid fa-arrow-right" />
                                  </span>
                                  <span className="icon-2">
                                    <i className="fa-solid fa-arrow-right" />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </div>
    </div>
  );
}

export default News;
